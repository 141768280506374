/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const WebAppConfig = {
    "url_s3_public_images": `${process.env.REACT_APP_URL_BUCKET}/public/`,
    "admon": "a5e0ea8d-95f6-4a8b-bd13-e28f9fa49934"
};


export default WebAppConfig;
