export const getCityName = (cod) => {
  const cityList = [
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "AGUA DE DIOS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "NEIVA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "QUIBDÓ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MONTERÍA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20001",
      CODIGO_AREA_METRO: "06",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VALLEDUPAR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "POPAYÁN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "18",
      CODIGO_DPTO_MPIO: "18001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FLORENCIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MANIZALES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "44",
      CODIGO_DPTO_MPIO: "44001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "06",
      NOMBRE_MUNICIPIO: "RIOHACHA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "04",
      NOMBRE_MUNICIPIO: "SANTA MARTA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VILLAVICENCIO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PASTO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54001",
      CODIGO_AREA_METRO: "05",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN JOSÉ DE CÚCUTA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "63",
      CODIGO_DPTO_MPIO: "63001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ARMENIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "66",
      CODIGO_DPTO_MPIO: "66001",
      CODIGO_AREA_METRO: "02",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PEREIRA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SINCELEJO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "IBAGUÉ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "07",
      NOMBRE_MUNICIPIO: "SANTIAGO DE CALI",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "88",
      CODIGO_DPTO_MPIO: "88001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN ANDRÉS",
      CODIGO_TIPO_MUNICIPIO: "ISLA",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "81",
      CODIGO_DPTO_MPIO: "81001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ARAUCA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "86",
      CODIGO_DPTO_MPIO: "86001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MOCOA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "91",
      CODIGO_DPTO_MPIO: "91001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LETICIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "94",
      CODIGO_DPTO_MPIO: "94001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "INÍRIDA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "95",
      CODIGO_DPTO_MPIO: "95001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN JOSÉ DEL GUAVIARE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "97",
      CODIGO_DPTO_MPIO: "97001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MITÚ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "99",
      CODIGO_DPTO_MPIO: "99001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO CARREÑO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "85",
      CODIGO_DPTO_MPIO: "85001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "YOPAL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05001",
      CODIGO_AREA_METRO: "01",
      CODIGO_DISTRITO: "10",
      NOMBRE_MUNICIPIO: "MEDELLÍN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TUNJA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68001",
      CODIGO_AREA_METRO: "03",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BUCARAMANGA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "08",
      CODIGO_DPTO_MPIO: "08001",
      CODIGO_AREA_METRO: "04",
      CODIGO_DISTRITO: "01",
      NOMBRE_MUNICIPIO: "BARRANQUILLA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "11",
      CODIGO_DPTO_MPIO: "11001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "02",
      NOMBRE_MUNICIPIO: "BOGOTÁ, D.C.",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "001",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13001",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "03",
      NOMBRE_MUNICIPIO: "CARTAGENA DE INDIAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "002",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05002",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ABEJORRAL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "003",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54003",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ÁBREGO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "004",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05004",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ABRIAQUÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "006",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50006",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ACACÍAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "006",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13006",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ACHÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "006",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41006",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ACEVEDO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "006",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27006",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ACANDÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "010",
      CODIGO_DEPARTAMENTO: "85",
      CODIGO_DPTO_MPIO: "85010",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "AGUAZUL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "011",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20011",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "AGUACHICA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "013",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68013",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "AGUADA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "013",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41013",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "AGRADO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "013",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17013",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "AGUADAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "013",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20013",
      CODIGO_AREA_METRO: "06",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "AGUSTÍN CODAZZI",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "015",
      CODIGO_DEPARTAMENTO: "95",
      CODIGO_DPTO_MPIO: "95015",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CALAMAR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "015",
      CODIGO_DEPARTAMENTO: "85",
      CODIGO_DPTO_MPIO: "85015",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHÁMEZA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "016",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41016",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "AIPE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "019",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52019",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ALBÁN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "019",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25019",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ALBÁN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "020",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68020",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ALBANIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "020",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41020",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ALGECIRAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "020",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76020",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ALCALÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "021",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05021",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ALEJANDRÍA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "022",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15022",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ALMEIDA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "022",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52022",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ALDANA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "022",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19022",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ALMAGUER",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "024",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73024",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ALPUJARRA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "025",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27025",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ALTO BAUDÓ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "025",
      CODIGO_DEPARTAMENTO: "95",
      CODIGO_DPTO_MPIO: "95025",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL RETORNO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "026",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41026",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ALTAMIRA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "026",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73026",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ALVARADO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "029",
      CODIGO_DEPARTAMENTO: "18",
      CODIGO_DPTO_MPIO: "18029",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ALBANIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "030",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13030",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ALTOS DEL ROSARIO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "030",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05030",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "AMAGÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "030",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73030",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "AMBALEMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "030",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47030",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ALGARROBO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "031",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05031",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "AMALFI",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "032",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20032",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ASTREA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "034",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05034",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ANDES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "035",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25035",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ANAPOIMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "035",
      CODIGO_DEPARTAMENTO: "44",
      CODIGO_DPTO_MPIO: "44035",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ALBANIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "036",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52036",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ANCUYA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "036",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76036",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ANDALUCÍA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "036",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05036",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ANGELÓPOLIS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "038",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05038",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ANGOSTURA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "040",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25040",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ANOLAIMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "040",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05040",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ANORÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "041",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76041",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ANSERMANUEVO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "042",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13042",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ARENAL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "042",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05042",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTA FÉ DE ANTIOQUIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "042",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17042",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ANSERMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "043",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73043",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ANZOÁTEGUI",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "044",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05044",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ANZÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "045",
      CODIGO_DEPARTAMENTO: "66",
      CODIGO_DPTO_MPIO: "66045",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "APÍA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "045",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20045",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BECERRIL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "045",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05045",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "APARTADÓ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "047",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15047",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "AQUITANIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "050",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27050",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ATRATO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "050",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17050",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ARANZAZU",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "050",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19050",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ARGELIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "051",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52051",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ARBOLEDA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "051",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54051",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ARBOLEDAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "051",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05051",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ARBOLETES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "051",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15051",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ARCABUCO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "051",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68051",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ARATOCA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "052",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13052",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ARJONA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "053",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47053",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ARACATACA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "053",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25053",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ARBELÁEZ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "054",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76054",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ARGELIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "055",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05055",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ARGELIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "055",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73055",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ARMERO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "058",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47058",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ARIGUANÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "059",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05059",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ARMENIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "060",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20060",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BOSCONIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "062",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13062",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ARROYOHONDO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "065",
      CODIGO_DEPARTAMENTO: "81",
      CODIGO_DPTO_MPIO: "81065",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ARAUQUITA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "067",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73067",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ATACO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "068",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23068",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "AYAPEL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "073",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27073",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BAGADÓ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "074",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13074",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BARRANCO DE LOBA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "075",
      CODIGO_DEPARTAMENTO: "66",
      CODIGO_DPTO_MPIO: "66075",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BALBOA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "075",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27075",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BAHÍA SOLANO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "075",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19075",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BALBOA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "077",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27077",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BAJO BAUDÓ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "077",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68077",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BARBOSA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "078",
      CODIGO_DEPARTAMENTO: "08",
      CODIGO_DPTO_MPIO: "08078",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BARANOA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "078",
      CODIGO_DEPARTAMENTO: "44",
      CODIGO_DPTO_MPIO: "44078",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BARRANCAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "078",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41078",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BARAYA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "079",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52079",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BARBACOAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "079",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68079",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BARICHARA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "079",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23079",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BUENAVISTA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "079",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05079",
      CODIGO_AREA_METRO: "01",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BARBOSA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "081",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68081",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "12",
      NOMBRE_MUNICIPIO: "BARRANCABERMEJA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "083",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52083",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BELÉN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "086",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05086",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BELMIRA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "086",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27086",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BELÉN DE BAJIRÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "086",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25086",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BELTRÁN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "087",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15087",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BELÉN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "088",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17088",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BELALCÁZAR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "088",
      CODIGO_DEPARTAMENTO: "66",
      CODIGO_DPTO_MPIO: "66088",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BELÉN DE UMBRÍA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "088",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05088",
      CODIGO_AREA_METRO: "01",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BELLO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "090",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15090",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BERBEO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "090",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23090",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CANALETE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "090",
      CODIGO_DEPARTAMENTO: "44",
      CODIGO_DPTO_MPIO: "44090",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "DIBULLA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "091",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05091",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BETANIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "092",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15092",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BETÉITIVA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "092",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68092",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BETULIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "093",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05093",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BETULIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "094",
      CODIGO_DEPARTAMENTO: "18",
      CODIGO_DPTO_MPIO: "18094",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BELÉN DE LOS ANDAQUÍES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "095",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25095",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BITUIMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "097",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15097",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BOAVITA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "098",
      CODIGO_DEPARTAMENTO: "44",
      CODIGO_DPTO_MPIO: "44098",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "DISTRACCIÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "099",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25099",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BOJACÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "099",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27099",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BOJAYÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "099",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54099",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BOCHALEMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "100",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19100",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BOLÍVAR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "100",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76100",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BOLÍVAR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "101",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05101",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CIUDAD BOLÍVAR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "101",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68101",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BOLÍVAR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "104",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15104",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BOYACÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "106",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15106",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BRICEÑO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "107",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05107",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BRICEÑO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "109",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54109",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BUCARASICA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "109",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15109",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BUENAVISTA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "109",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76109",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "05",
      NOMBRE_MUNICIPIO: "BUENAVENTURA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "110",
      CODIGO_DEPARTAMENTO: "44",
      CODIGO_DPTO_MPIO: "44110",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL MOLINO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "110",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50110",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BARRANCA DE UPÍA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "110",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70110",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BUENAVISTA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "110",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19110",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BUENOS AIRES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "110",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52110",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BUESACO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "111",
      CODIGO_DEPARTAMENTO: "63",
      CODIGO_DPTO_MPIO: "63111",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BUENAVISTA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "111",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76111",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUADALAJARA DE BUGA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "113",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05113",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BURITICÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "113",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76113",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BUGALAGRANDE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "114",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15114",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BUSBANZÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "120",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05120",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CÁCERES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "120",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25120",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CABRERA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "121",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68121",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CABRERA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "122",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76122",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CAICEDONIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "123",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25123",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CACHIPAY",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "124",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50124",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CABUYARO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "124",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70124",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CAIMITO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "124",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73124",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CAJAMARCA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "125",
      CODIGO_DEPARTAMENTO: "85",
      CODIGO_DPTO_MPIO: "85125",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "HATO COROZAL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "125",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05125",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CAICEDO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "125",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54125",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CÁCOTA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "126",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76126",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CALIMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "126",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25126",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CAJICÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "128",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54128",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CÁCHIRA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "129",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05129",
      CODIGO_AREA_METRO: "01",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CALDAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "130",
      CODIGO_DEPARTAMENTO: "63",
      CODIGO_DPTO_MPIO: "63130",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CALARCÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "130",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76130",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CANDELARIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "130",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19130",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CAJIBÍO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "131",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15131",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CALDAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "132",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68132",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CALIFORNIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "132",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41132",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CAMPOALEGRE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "134",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05134",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CAMPAMENTO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "135",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15135",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CAMPOHERMOSO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "135",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27135",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL CANTÓN DEL SAN PABLO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "136",
      CODIGO_DEPARTAMENTO: "85",
      CODIGO_DPTO_MPIO: "85136",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA SALINA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "137",
      CODIGO_DEPARTAMENTO: "08",
      CODIGO_DPTO_MPIO: "08137",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CAMPO DE LA CRUZ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "137",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19137",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CALDONO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "138",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05138",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CAÑASGORDAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "139",
      CODIGO_DEPARTAMENTO: "85",
      CODIGO_DPTO_MPIO: "85139",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MANÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "140",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13140",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CALAMAR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "141",
      CODIGO_DEPARTAMENTO: "08",
      CODIGO_DPTO_MPIO: "08141",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CANDELARIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "142",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05142",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CARACOLÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "142",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19142",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CALOTO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "145",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05145",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CARAMANTA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "147",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05147",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CAREPA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "147",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76147",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CARTAGO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "147",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68147",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CAPITANEJO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "148",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25148",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CAPARRAPÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "148",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73148",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CARMEN DE APICALÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "148",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05148",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL CARMEN DE VIBORAL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "150",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05150",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CAROLINA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "150",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50150",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CASTILLA LA NUEVA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "150",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27150",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CARMEN DEL DARIÉN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "150",
      CODIGO_DEPARTAMENTO: "18",
      CODIGO_DPTO_MPIO: "18150",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CARTAGENA DEL CHAIRÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "151",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25151",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CÁQUEZA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "152",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68152",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CARCASÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "152",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73152",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CASABIANCA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "154",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25154",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CARMEN DE CARUPA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "154",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05154",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CAUCASIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "160",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27160",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CÉRTEGUI",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "160",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68160",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CEPITÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "160",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13160",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CANTAGALLO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "161",
      CODIGO_DEPARTAMENTO: "97",
      CODIGO_DPTO_MPIO: "97161",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CARURÚ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "161",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47161",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CERRO DE SAN ANTONIO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "162",
      CODIGO_DEPARTAMENTO: "85",
      CODIGO_DPTO_MPIO: "85162",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MONTERREY",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "162",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15162",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CERINZA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "162",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23162",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CERETÉ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "162",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68162",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CERRITO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "167",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68167",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHARALÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "168",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23168",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHIMÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "168",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25168",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHAGUANÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "168",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73168",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHAPARRAL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "169",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68169",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHARTA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "170",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47170",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHIVOLO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "170",
      CODIGO_DEPARTAMENTO: "66",
      CODIGO_DPTO_MPIO: "66170",
      CODIGO_AREA_METRO: "02",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "DOSQUEBRADAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "172",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54172",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHINÁCOTA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "172",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05172",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHIGORODÓ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "172",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15172",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHINAVITA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "174",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17174",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHINCHINÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "174",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54174",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHITAGÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "175",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25175",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHÍA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "175",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20175",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHIMICHAGUA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "176",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68176",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHIMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "176",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15176",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHIQUINQUIRÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "178",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25178",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHIPAQUE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "178",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20178",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHIRIGUANÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "179",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68179",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHIPATÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "180",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15180",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHISCAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "181",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25181",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHOACHÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "182",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23182",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHINÚ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "183",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15183",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHITA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "183",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25183",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHOCONTÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "185",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15185",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHITARAQUE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "187",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15187",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHIVATÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "188",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13188",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CICUCO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "189",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23189",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CIÉNAGA DE ORO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "189",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47189",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CIÉNAGA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "189",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15189",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CIÉNEGA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "190",
      CODIGO_DEPARTAMENTO: "63",
      CODIGO_DPTO_MPIO: "63190",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CIRCASIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "190",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68190",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CIMITARRA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "190",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05190",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CISNEROS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "197",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05197",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "COCORNÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "200",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73200",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "COELLO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "200",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25200",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "COGUA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "200",
      CODIGO_DEPARTAMENTO: "95",
      CODIGO_DPTO_MPIO: "95200",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MIRAFLORES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "203",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52203",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "COLÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "204",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70204",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "COLOSÓ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "204",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15204",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CÓMBITA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "205",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27205",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CONDOTO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "205",
      CODIGO_DEPARTAMENTO: "18",
      CODIGO_DPTO_MPIO: "18205",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CURILLO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "205",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47205",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CONCORDIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "206",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05206",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CONCEPCIÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "206",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54206",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CONVENCIÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "206",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41206",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "COLOMBIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "207",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52207",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CONSACÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "207",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68207",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CONCEPCIÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "209",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68209",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CONFINES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "209",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05209",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CONCORDIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "210",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52210",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CONTADERO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "211",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68211",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CONTRATACIÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "212",
      CODIGO_DEPARTAMENTO: "63",
      CODIGO_DPTO_MPIO: "63212",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CÓRDOBA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "212",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19212",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CORINTO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "212",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13212",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CÓRDOBA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "212",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15212",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "COPER",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "212",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05212",
      CODIGO_AREA_METRO: "01",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "COPACABANA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "214",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25214",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "COTA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "215",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15215",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CORRALES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "215",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52215",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CÓRDOBA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "215",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70215",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "COROZAL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "217",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73217",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "COYAIMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "217",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68217",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "COROMORO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "218",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15218",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "COVARACHÍA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "219",
      CODIGO_DEPARTAMENTO: "86",
      CODIGO_DPTO_MPIO: "86219",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "COLÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "220",
      CODIGO_DEPARTAMENTO: "81",
      CODIGO_DPTO_MPIO: "81220",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CRAVO NORTE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "221",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70221",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "COVEÑAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "222",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13222",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CLEMENCIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "223",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54223",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CUCUTILLA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "223",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50223",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CUBARRAL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "223",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15223",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CUBARÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "224",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15224",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CUCAITA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "224",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52224",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CUASPUD CARLOSAMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "224",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25224",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CUCUNUBÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "225",
      CODIGO_DEPARTAMENTO: "85",
      CODIGO_DPTO_MPIO: "85225",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "NUNCHÍA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "226",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50226",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CUMARAL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "226",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15226",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CUÍTIVA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "226",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73226",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CUNDAY",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "227",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52227",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CUMBAL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "228",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20228",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CURUMANÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "229",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68229",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CURITÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "230",
      CODIGO_DEPARTAMENTO: "85",
      CODIGO_DPTO_MPIO: "85230",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "OROCUÉ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "230",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70230",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHALÁN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "232",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15232",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHÍQUIZA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "233",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70233",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL ROBLE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "233",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76233",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "DAGUA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "233",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52233",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CUMBITARA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "234",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05234",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "DABEIBA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "235",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68235",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL CARMEN DE CHUCURÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "235",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70235",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GALERAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "236",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15236",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHIVOR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "236",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73236",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "DOLORES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "237",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05237",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "DONMATÍAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "238",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20238",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL COPEY",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "238",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15238",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "DUITAMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "239",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54239",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "DURANIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "240",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52240",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CHACHAGÜÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "240",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05240",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EBÉJICO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "243",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76243",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL ÁGUILA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "244",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41244",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ELÍAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "244",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13244",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL CARMEN DE BOLÍVAR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "244",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15244",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL COCUY",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "245",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25245",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL COLEGIO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "245",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47245",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL BANCO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "245",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54245",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL CARMEN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "245",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27245",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL CARMEN DE ATRATO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "245",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68245",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL GUACAMAYO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "245",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50245",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL CALVARIO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "246",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76246",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL CAIRO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "247",
      CODIGO_DEPARTAMENTO: "18",
      CODIGO_DPTO_MPIO: "18247",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL DONCELLO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "248",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15248",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL ESPINO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "248",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13248",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL GUAMO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "248",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76248",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL CERRITO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "250",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27250",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL LITORAL DEL SAN JUAN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "250",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54250",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL TARRA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "250",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68250",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL PEÑÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "250",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20250",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL PASO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "250",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76250",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL DOVIO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "250",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05250",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL BAGRE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "250",
      CODIGO_DEPARTAMENTO: "85",
      CODIGO_DPTO_MPIO: "85250",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PAZ DE ARIPORO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "250",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52250",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL CHARCO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "251",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50251",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL CASTILLO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "254",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52254",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL PEÑOL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "255",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68255",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL PLAYÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "256",
      CODIGO_DEPARTAMENTO: "18",
      CODIGO_DPTO_MPIO: "18256",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL PAUJÍL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "256",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52256",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL ROSARIO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "256",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19256",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL TAMBO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "258",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25258",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL PEÑÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "258",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52258",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL TABLÓN DE GÓMEZ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "258",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47258",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL PIÑÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "260",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25260",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL ROSAL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "260",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52260",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL TAMBO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "261",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54261",
      CODIGO_AREA_METRO: "05",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL ZULIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "263",
      CODIGO_DEPARTAMENTO: "91",
      CODIGO_DPTO_MPIO: "91263",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL ENCANTO (ANM)",
      CODIGO_TIPO_MUNICIPIO: "ANM",
    },
    {
      CODIGO_MUNICIPIO: "263",
      CODIGO_DEPARTAMENTO: "85",
      CODIGO_DPTO_MPIO: "85263",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PORE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "264",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68264",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ENCINO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "264",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05264",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ENTRERRÍOS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "265",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70265",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUARANDA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "266",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68266",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ENCISO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "266",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05266",
      CODIGO_AREA_METRO: "01",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ENVIGADO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "268",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47268",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL RETÉN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "268",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73268",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ESPINAL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "268",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13268",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL PEÑÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "269",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25269",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FACATATIVÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "270",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50270",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL DORADO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "270",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73270",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FALAN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "271",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68271",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FLORIÁN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "272",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17272",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FILADELFIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "272",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15272",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FIRAVITOBA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "272",
      CODIGO_DEPARTAMENTO: "63",
      CODIGO_DPTO_MPIO: "63272",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FILANDIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "275",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73275",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FLANDES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "275",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76275",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FLORIDA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "276",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15276",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FLORESTA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "276",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68276",
      CODIGO_AREA_METRO: "03",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FLORIDABLANCA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "279",
      CODIGO_DEPARTAMENTO: "44",
      CODIGO_DPTO_MPIO: "44279",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FONSECA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "279",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25279",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FÓMEQUE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "279",
      CODIGO_DEPARTAMENTO: "85",
      CODIGO_DPTO_MPIO: "85279",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "RECETOR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "281",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25281",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FOSCA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "282",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05282",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FREDONIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "283",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73283",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FRESNO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "284",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05284",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FRONTINO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "286",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25286",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FUNZA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "287",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52287",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FUNES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "287",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50287",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FUENTEDEORO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "288",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47288",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FUNDACIÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "288",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25288",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FÚQUENE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "290",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19290",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FLORENCIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "290",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25290",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FUSAGASUGÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "293",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15293",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GACHANTIVÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "293",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25293",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GACHALÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "295",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25295",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GACHANCIPÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "295",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20295",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GAMARRA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "296",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68296",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GALÁN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "296",
      CODIGO_DEPARTAMENTO: "08",
      CODIGO_DPTO_MPIO: "08296",
      CODIGO_AREA_METRO: "04",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GALAPA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "296",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15296",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GÁMEZA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "297",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25297",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GACHETÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "298",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41298",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GARZÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "298",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68298",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GÁMBITA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "299",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25299",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GAMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "299",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15299",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GARAGOA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "300",
      CODIGO_DEPARTAMENTO: "81",
      CODIGO_DPTO_MPIO: "81300",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FORTUL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "300",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19300",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUACHENÉ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "300",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13300",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "HATILLO DE LOBA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "300",
      CODIGO_DEPARTAMENTO: "85",
      CODIGO_DPTO_MPIO: "85300",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SABANALARGA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "300",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23300",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "COTORRA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "302",
      CODIGO_DEPARTAMENTO: "63",
      CODIGO_DPTO_MPIO: "63302",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GÉNOVA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "306",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05306",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GIRALDO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "306",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76306",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GINEBRA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "306",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41306",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GIGANTE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "307",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25307",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GIRARDOT",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "307",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68307",
      CODIGO_AREA_METRO: "03",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GIRÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "308",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05308",
      CODIGO_AREA_METRO: "01",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GIRARDOTA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "310",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05310",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GÓMEZ PLATA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "310",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20310",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GONZÁLEZ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "312",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25312",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GRANADA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "313",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54313",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GRAMALOTE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "313",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50313",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GRANADA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "313",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05313",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GRANADA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "315",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05315",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUADALUPE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "315",
      CODIGO_DEPARTAMENTO: "85",
      CODIGO_DPTO_MPIO: "85315",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SÁCAMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "317",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25317",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUACHETÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "317",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52317",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUACHUCAL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "317",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15317",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUACAMAYAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "318",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19318",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUAPI",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "318",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50318",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUAMAL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "318",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47318",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUAMAL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "318",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68318",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUACA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "318",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76318",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUACARÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "318",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05318",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUARNE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "318",
      CODIGO_DEPARTAMENTO: "66",
      CODIGO_DPTO_MPIO: "66318",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUÁTICA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "319",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41319",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUADALUPE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "319",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73319",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUAMO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "320",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52320",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUAITARILLA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "320",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25320",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUADUAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "320",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68320",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUADALUPE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "320",
      CODIGO_DEPARTAMENTO: "86",
      CODIGO_DPTO_MPIO: "86320",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ORITO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "321",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05321",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUATAPÉ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "322",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25322",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUASCA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "322",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15322",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUATEQUE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "322",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68322",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUAPOTÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "323",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52323",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUALMATÁN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "324",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25324",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUATAQUÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "324",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68324",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUAVATÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "325",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50325",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MAPIRIPÁN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "325",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15325",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUAYATÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "325",
      CODIGO_DEPARTAMENTO: "85",
      CODIGO_DPTO_MPIO: "85325",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN LUIS DE PALENQUE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "326",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25326",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUATAVITA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "327",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68327",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GÜEPSA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "328",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25328",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUAYABAL DE SÍQUIMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "330",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50330",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MESETAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "332",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15332",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GÜICÁN DE LA SIERRA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "335",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25335",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUAYABETAL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "339",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25339",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "GUTIÉRREZ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "343",
      CODIGO_DEPARTAMENTO: "94",
      CODIGO_DPTO_MPIO: "94343",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "BARRANCOMINAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "344",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68344",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "HATO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "344",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54344",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "HACARÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "347",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54347",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "HERRÁN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "347",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73347",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "HERVEO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "347",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05347",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "HELICONIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "349",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41349",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "HOBO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "349",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73349",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "HONDA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "350",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23350",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA APARTADA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "350",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50350",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA MACARENA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "352",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73352",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ICONONZO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "352",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52352",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ILES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "353",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05353",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "HISPANIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "354",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52354",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "IMUÉS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "355",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19355",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "INZÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "356",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52356",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "IPIALES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "357",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41357",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ÍQUIRA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "359",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41359",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ISNOS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "360",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52360",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "JARDINES DE SUCUMBÍOS",
      CODIGO_TIPO_MUNICIPIO: "ANM",
    },
    {
      CODIGO_MUNICIPIO: "360",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05360",
      CODIGO_AREA_METRO: "01",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ITAGÜÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "361",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27361",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ISTMINA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "361",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05361",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ITUANGO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "362",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15362",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "IZA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "364",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76364",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "JAMUNDÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "364",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19364",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "JAMBALÓ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "364",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05364",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "JARDÍN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "367",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15367",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "JENESANO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "368",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05368",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "JERICÓ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "368",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15368",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "JERICÓ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "368",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25368",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "JERUSALÉN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "368",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68368",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "JESÚS MARÍA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "370",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50370",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "URIBE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "370",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68370",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "JORDÁN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "372",
      CODIGO_DEPARTAMENTO: "08",
      CODIGO_DPTO_MPIO: "08372",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "JUAN DE ACOSTA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "372",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27372",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "JURADÓ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "372",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25372",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "JUNÍN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "376",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05376",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA CEJA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "377",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76377",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA CUMBRE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "377",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68377",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA BELLEZA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "377",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54377",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LABATECA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "377",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25377",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA CALERA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "377",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15377",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LABRANZAGRANDE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "378",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41378",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA ARGENTINA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "378",
      CODIGO_DEPARTAMENTO: "44",
      CODIGO_DPTO_MPIO: "44378",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "HATONUEVO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "378",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52378",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA CRUZ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "380",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15380",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA CAPILLA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "380",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05380",
      CODIGO_AREA_METRO: "01",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA ESTRELLA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "380",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17380",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA DORADA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "381",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52381",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA FLORIDA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "383",
      CODIGO_DEPARTAMENTO: "66",
      CODIGO_DPTO_MPIO: "66383",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA CELIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "383",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20383",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA GLORIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "385",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54385",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA ESPERANZA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "385",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52385",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA LLANADA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "385",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68385",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LANDÁZURI",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "386",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25386",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA MESA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "388",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17388",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA MERCED",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "390",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52390",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA TOLA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "390",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05390",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA PINTADA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "392",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19392",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA SIERRA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "394",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25394",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA PALMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "396",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41396",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA PLATA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "397",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68397",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA PAZ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "397",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19397",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA VEGA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "398",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25398",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA PEÑA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "398",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54398",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA PLAYA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "399",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52399",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA UNIÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "400",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70400",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA UNIÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "400",
      CODIGO_DEPARTAMENTO: "66",
      CODIGO_DPTO_MPIO: "66400",
      CODIGO_AREA_METRO: "02",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA VIRGINIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "400",
      CODIGO_DEPARTAMENTO: "85",
      CODIGO_DPTO_MPIO: "85400",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TÁMARA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "400",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05400",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA UNIÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "400",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50400",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LEJANÍAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "400",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20400",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA JAGUA DE IBIRICO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "400",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76400",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA UNIÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "401",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15401",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA VICTORIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "401",
      CODIGO_DEPARTAMENTO: "63",
      CODIGO_DPTO_MPIO: "63401",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA TEBAIDA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "402",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25402",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA VEGA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "403",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76403",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA VICTORIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "403",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15403",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA UVITA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "405",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54405",
      CODIGO_AREA_METRO: "05",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LOS PATIOS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "405",
      CODIGO_DEPARTAMENTO: "91",
      CODIGO_DPTO_MPIO: "91405",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA CHORRERA (ANM)",
      CODIGO_TIPO_MUNICIPIO: "ANM",
    },
    {
      CODIGO_MUNICIPIO: "405",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52405",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LEIVA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "406",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68406",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LEBRIJA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "407",
      CODIGO_DEPARTAMENTO: "91",
      CODIGO_DPTO_MPIO: "91407",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA PEDRERA (ANM)",
      CODIGO_TIPO_MUNICIPIO: "ANM",
    },
    {
      CODIGO_MUNICIPIO: "407",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15407",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VILLA DE LEYVA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "407",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25407",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LENGUAZAQUE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "408",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73408",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LÉRIDA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "410",
      CODIGO_DEPARTAMENTO: "18",
      CODIGO_DPTO_MPIO: "18410",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA MONTAÑITA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "410",
      CODIGO_DEPARTAMENTO: "85",
      CODIGO_DPTO_MPIO: "85410",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TAURAMENA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "411",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73411",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LÍBANO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "411",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05411",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LIBORINA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "411",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52411",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LINARES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "413",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27413",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LLORÓ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "417",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23417",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LORICA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "418",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70418",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LOS PALMITOS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "418",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54418",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LOURDES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "418",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68418",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LOS SANTOS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "418",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19418",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LÓPEZ DE MICAY",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "418",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52418",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LOS ANDES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "419",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23419",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LOS CÓRDOBAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "420",
      CODIGO_DEPARTAMENTO: "44",
      CODIGO_DPTO_MPIO: "44420",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA JAGUA DEL PILAR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "421",
      CODIGO_DEPARTAMENTO: "08",
      CODIGO_DPTO_MPIO: "08421",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LURUACO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "425",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05425",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MACEO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "425",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27425",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MEDIO ATRATO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "425",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15425",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MACANAL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "425",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68425",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MACARAVITA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "426",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25426",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MACHETÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "427",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52427",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MAGÜÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "429",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70429",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MAJAGUAL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "430",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13430",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MAGANGUÉ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "430",
      CODIGO_DEPARTAMENTO: "44",
      CODIGO_DPTO_MPIO: "44430",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MAICAO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "430",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25430",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MADRID",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "430",
      CODIGO_DEPARTAMENTO: "91",
      CODIGO_DPTO_MPIO: "91430",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA VICTORIA (ANM)",
      CODIGO_TIPO_MUNICIPIO: "ANM",
    },
    {
      CODIGO_MUNICIPIO: "430",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27430",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MEDIO BAUDÓ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "430",
      CODIGO_DEPARTAMENTO: "85",
      CODIGO_DPTO_MPIO: "85430",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TRINIDAD",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "432",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68432",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MÁLAGA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "433",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17433",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MANZANARES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "433",
      CODIGO_DEPARTAMENTO: "08",
      CODIGO_DPTO_MPIO: "08433",
      CODIGO_AREA_METRO: "04",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MALAMBO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "433",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13433",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MAHATES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "435",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52435",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MALLAMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "436",
      CODIGO_DEPARTAMENTO: "08",
      CODIGO_DPTO_MPIO: "08436",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MANATÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "436",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25436",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MANTA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "438",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25438",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MEDINA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "440",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05440",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MARINILLA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "440",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13440",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MARGARITA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "440",
      CODIGO_DEPARTAMENTO: "66",
      CODIGO_DPTO_MPIO: "66440",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MARSELLA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "440",
      CODIGO_DEPARTAMENTO: "85",
      CODIGO_DPTO_MPIO: "85440",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VILLANUEVA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "442",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13442",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MARÍA LA BAJA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "442",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15442",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MARIPÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "442",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17442",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MARMATO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "443",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20443",
      CODIGO_AREA_METRO: "06",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MANAURE BALCÓN DEL CESAR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "443",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73443",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN SEBASTIÁN DE MARIQUITA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "444",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68444",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MATANZA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "444",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17444",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MARQUETALIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "446",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17446",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MARULANDA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "449",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73449",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MELGAR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "450",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19450",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MERCADERES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "450",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27450",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MEDIO SAN JUAN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "450",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50450",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO CONCORDIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "455",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15455",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MIRAFLORES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "455",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19455",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MIRANDA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "456",
      CODIGO_DEPARTAMENTO: "66",
      CODIGO_DPTO_MPIO: "66456",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MISTRATÓ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "458",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13458",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MONTECRISTO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "460",
      CODIGO_DEPARTAMENTO: "91",
      CODIGO_DPTO_MPIO: "91460",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MIRITÍ - PARANÁ (ANM)",
      CODIGO_TIPO_MUNICIPIO: "ANM",
    },
    {
      CODIGO_MUNICIPIO: "460",
      CODIGO_DEPARTAMENTO: "18",
      CODIGO_DPTO_MPIO: "18460",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MILÁN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "460",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47460",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "NUEVA GRANADA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "461",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73461",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MURILLO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "464",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68464",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MOGOTES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "464",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15464",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MONGUA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "464",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23464",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MOMIL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "466",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23466",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MONTELÍBANO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "466",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15466",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MONGUÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "467",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05467",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MONTEBELLO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "468",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13468",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "08",
      NOMBRE_MUNICIPIO: "SANTA CRUZ DE MOMPOX",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "468",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68468",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MOLAGAVITA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "469",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15469",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MONIQUIRÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "470",
      CODIGO_DEPARTAMENTO: "63",
      CODIGO_DPTO_MPIO: "63470",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MONTENEGRO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "473",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70473",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MORROA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "473",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25473",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MOSQUERA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "473",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13473",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MORALES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "473",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19473",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MORALES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "473",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52473",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MOSQUERA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "475",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05475",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MURINDÓ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "476",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15476",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MOTAVITA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "479",
      CODIGO_DEPARTAMENTO: "18",
      CODIGO_DPTO_MPIO: "18479",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MORELIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "480",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52480",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "NARIÑO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "480",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15480",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MUZO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "480",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54480",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MUTISCUA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "480",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05480",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MUTATÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "483",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41483",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "NÁTAGA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "483",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25483",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "NARIÑO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "483",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73483",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "NATAGAIMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "483",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05483",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "NARIÑO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "486",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17486",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "NEIRA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "486",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25486",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "NEMOCÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "488",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25488",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "NILO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "489",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25489",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "NIMAIMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "490",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52490",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "OLAYA HERRERA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "490",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13490",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "NOROSÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "490",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05490",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "NECOCLÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "491",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15491",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "NOBSA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "491",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27491",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "NÓVITA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "491",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25491",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "NOCAIMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "493",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27493",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "NUEVO BELÉN DE BAJIRÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "494",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15494",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "NUEVO COLÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "495",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05495",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "NECHÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "495",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27495",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "NUQUÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "495",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17495",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "NORCASIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "497",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76497",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "OBANDO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "498",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68498",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "OCAMONTE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "498",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54498",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "OCAÑA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "500",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68500",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "OIBA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "500",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15500",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "OICATÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "500",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23500",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MOÑITOS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "501",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05501",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "OLAYA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "502",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68502",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ONZAGA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "503",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41503",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "OPORAPA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "504",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73504",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ORTEGA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "506",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25506",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VENECIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "506",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52506",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "OSPINA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "507",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15507",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "OTANCHE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "508",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70508",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "OVEJAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "511",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15511",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PACHAVITA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "511",
      CODIGO_DEPARTAMENTO: "97",
      CODIGO_DPTO_MPIO: "97511",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PACOA (ANM)",
      CODIGO_TIPO_MUNICIPIO: "ANM",
    },
    {
      CODIGO_MUNICIPIO: "513",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17513",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PÁCORA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "513",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25513",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PACHO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "513",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19513",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PADILLA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "514",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15514",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PÁEZ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "516",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15516",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PAIPA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "517",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20517",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PAILITAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "517",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19517",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PÁEZ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "518",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41518",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PAICOL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "518",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25518",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PAIME",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "518",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54518",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PAMPLONA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "518",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15518",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PAJARITO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "520",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52520",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "FRANCISCO PIZARRO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "520",
      CODIGO_DEPARTAMENTO: "08",
      CODIGO_DPTO_MPIO: "08520",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PALMAR DE VARELA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "520",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76520",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PALMIRA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "520",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73520",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PALOCABILDO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "520",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54520",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PAMPLONITA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "522",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15522",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PANQUEBA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "522",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68522",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PALMAR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "523",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70523",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PALMITO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "524",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41524",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PALERMO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "524",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17524",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PALESTINA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "524",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68524",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PALMAS DEL SOCORRO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "524",
      CODIGO_DEPARTAMENTO: "99",
      CODIGO_DPTO_MPIO: "99524",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA PRIMAVERA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "524",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25524",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PANDI",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "530",
      CODIGO_DEPARTAMENTO: "91",
      CODIGO_DPTO_MPIO: "91530",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO ALEGRÍA (ANM)",
      CODIGO_TIPO_MUNICIPIO: "ANM",
    },
    {
      CODIGO_MUNICIPIO: "530",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25530",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PARATEBUENO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "530",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41530",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PALESTINA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "531",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15531",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PAUNA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "532",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19532",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PATÍA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "533",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15533",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PAYA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "533",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68533",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PÁRAMO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "533",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19533",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PIAMONTE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "535",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25535",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PASCA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "536",
      CODIGO_DEPARTAMENTO: "91",
      CODIGO_DPTO_MPIO: "91536",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO ARICA (ANM)",
      CODIGO_TIPO_MUNICIPIO: "ANM",
    },
    {
      CODIGO_MUNICIPIO: "537",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15537",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PAZ DE RÍO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "540",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52540",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "POLICARPA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "540",
      CODIGO_DEPARTAMENTO: "91",
      CODIGO_DPTO_MPIO: "91540",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO NARIÑO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "541",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47541",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PEDRAZA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "541",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17541",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PENSILVANIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "541",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05541",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PEÑOL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "542",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15542",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PESCA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "543",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05543",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PEQUE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "545",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47545",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PIJIÑO DEL CARMEN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "547",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73547",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PIEDRAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "547",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68547",
      CODIGO_AREA_METRO: "03",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PIEDECUESTA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "548",
      CODIGO_DEPARTAMENTO: "63",
      CODIGO_DPTO_MPIO: "63548",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PIJAO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "548",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19548",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PIENDAMÓ - TUNÍA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "548",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41548",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PITAL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "549",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68549",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PINCHOTE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "549",
      CODIGO_DEPARTAMENTO: "08",
      CODIGO_DPTO_MPIO: "08549",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PIOJÓ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "549",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13549",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PINILLOS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "550",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20550",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PELAYA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "550",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15550",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PISBA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "551",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41551",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PITALITO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "551",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47551",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PIVIJAY",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "553",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54553",
      CODIGO_AREA_METRO: "05",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO SANTANDER",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "555",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73555",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PLANADAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "555",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47555",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PLATO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "555",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23555",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PLANETA RICA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "558",
      CODIGO_DEPARTAMENTO: "08",
      CODIGO_DPTO_MPIO: "08558",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "POLONUEVO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "560",
      CODIGO_DEPARTAMENTO: "08",
      CODIGO_DPTO_MPIO: "08560",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PONEDERA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "560",
      CODIGO_DEPARTAMENTO: "44",
      CODIGO_DPTO_MPIO: "44560",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MANAURE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "560",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52560",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "POTOSÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "563",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76563",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PRADERA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "563",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73563",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PRADO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "564",
      CODIGO_DEPARTAMENTO: "88",
      CODIGO_DPTO_MPIO: "88564",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PROVIDENCIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "565",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52565",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PROVIDENCIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "568",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50568",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO GAITÁN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "568",
      CODIGO_DEPARTAMENTO: "86",
      CODIGO_DPTO_MPIO: "86568",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO ASÍS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "569",
      CODIGO_DEPARTAMENTO: "86",
      CODIGO_DPTO_MPIO: "86569",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO CAICEDO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "570",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20570",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUEBLO BELLO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "570",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47570",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUEBLOVIEJO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "570",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23570",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUEBLO NUEVO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "571",
      CODIGO_DEPARTAMENTO: "86",
      CODIGO_DPTO_MPIO: "86571",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO GUZMÁN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "572",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15572",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO BOYACÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "572",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25572",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO SALGAR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "572",
      CODIGO_DEPARTAMENTO: "99",
      CODIGO_DPTO_MPIO: "99572",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTA RITA",
      CODIGO_TIPO_MUNICIPIO: "ANM",
    },
    {
      CODIGO_MUNICIPIO: "572",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68572",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUENTE NACIONAL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "572",
      CODIGO_DEPARTAMENTO: "66",
      CODIGO_DPTO_MPIO: "66572",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUEBLO RICO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "573",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68573",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO PARRA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "573",
      CODIGO_DEPARTAMENTO: "08",
      CODIGO_DPTO_MPIO: "08573",
      CODIGO_AREA_METRO: "04",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO COLOMBIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "573",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52573",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERRES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "573",
      CODIGO_DEPARTAMENTO: "86",
      CODIGO_DPTO_MPIO: "86573",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO LEGUÍZAMO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "573",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50573",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO LÓPEZ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "573",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19573",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO TEJADA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "574",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23574",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO ESCONDIDO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "575",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68575",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO WILCHES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "576",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05576",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUEBLORRICO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "577",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50577",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO LLERAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "579",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05579",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO BERRÍO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "580",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25580",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PULÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "580",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23580",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO LIBERTADOR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "580",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13580",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "REGIDOR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "580",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27580",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "RÍO IRÓ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "580",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15580",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "QUÍPAMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "585",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05585",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO NARE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "585",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73585",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PURIFICACIÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "585",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52585",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUPIALES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "585",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19585",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PURACÉ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "586",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23586",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PURÍSIMA DE LA CONCEPCIÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "590",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50590",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO RICO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "591",
      CODIGO_DEPARTAMENTO: "81",
      CODIGO_DPTO_MPIO: "81591",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO RONDÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "591",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05591",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO TRIUNFO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "592",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25592",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "QUEBRADANEGRA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "592",
      CODIGO_DEPARTAMENTO: "18",
      CODIGO_DPTO_MPIO: "18592",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO RICO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "594",
      CODIGO_DEPARTAMENTO: "63",
      CODIGO_DPTO_MPIO: "63594",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "QUIMBAYA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "594",
      CODIGO_DEPARTAMENTO: "66",
      CODIGO_DPTO_MPIO: "66594",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "QUINCHÍA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "594",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25594",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "QUETAME",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "596",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25596",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "QUIPILE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "599",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54599",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "RAGONVALIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "599",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15599",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "RAMIRIQUÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "599",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25599",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "APULO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "600",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13600",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "RÍO VIEJO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "600",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15600",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "RÁQUIRA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "600",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27600",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "RÍO QUITO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "604",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05604",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "REMEDIOS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "605",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47605",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "REMOLINO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "606",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76606",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "RESTREPO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "606",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50606",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "RESTREPO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "606",
      CODIGO_DEPARTAMENTO: "08",
      CODIGO_DPTO_MPIO: "08606",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "REPELÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "607",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05607",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "RETIRO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "610",
      CODIGO_DEPARTAMENTO: "18",
      CODIGO_DPTO_MPIO: "18610",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN JOSÉ DEL FRAGUA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "612",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52612",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "RICAURTE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "612",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25612",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "RICAURTE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "614",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20614",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "RÍO DE ORO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "614",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17614",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "RIOSUCIO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "615",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41615",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "RIVERA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "615",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68615",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "RIONEGRO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "615",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05615",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "RIONEGRO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "615",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27615",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "RIOSUCIO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "616",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76616",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "RIOFRÍO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "616",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17616",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "RISARALDA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "616",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73616",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "RIOBLANCO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "620",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13620",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN CRISTÓBAL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "621",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52621",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ROBERTO PAYÁN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "621",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20621",
      CODIGO_AREA_METRO: "06",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA PAZ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "621",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15621",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "RONDÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "622",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73622",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "RONCESVALLES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "622",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76622",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ROLDANILLO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "622",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19622",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ROSAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "624",
      CODIGO_DEPARTAMENTO: "99",
      CODIGO_DPTO_MPIO: "99624",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTA ROSALÍA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "624",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73624",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ROVIRA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "628",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05628",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SABANALARGA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "631",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05631",
      CODIGO_AREA_METRO: "01",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SABANETA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "632",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15632",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SABOYÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "634",
      CODIGO_DEPARTAMENTO: "08",
      CODIGO_DPTO_MPIO: "08634",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SABANAGRANDE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "638",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15638",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SÁCHICA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "638",
      CODIGO_DEPARTAMENTO: "08",
      CODIGO_DPTO_MPIO: "08638",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SABANALARGA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "642",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05642",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SALGAR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "645",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25645",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN ANTONIO DEL TEQUENDAMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "646",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15646",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAMACÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "647",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13647",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN ESTANISLAO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "647",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05647",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN ANDRÉS DE CUERQUÍA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "649",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25649",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN BERNARDO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "649",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05649",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN CARLOS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "650",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13650",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN FERNANDO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "650",
      CODIGO_DEPARTAMENTO: "44",
      CODIGO_DPTO_MPIO: "44650",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN JUAN DEL CESAR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "652",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05652",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN FRANCISCO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "653",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17653",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SALAMINA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "653",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25653",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN CAYETANO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "654",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13654",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN JACINTO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "655",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13655",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN JACINTO DEL CAUCA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "655",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68655",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SABANA DE TORRES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "656",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05656",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN JERÓNIMO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "657",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13657",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN JUAN NEPOMUCENO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "658",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25658",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN FRANCISCO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "658",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05658",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN JOSÉ DE LA MONTAÑA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "659",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05659",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN JUAN DE URABÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "660",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47660",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SABANAS DE SAN ÁNGEL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "660",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27660",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN JOSÉ DEL PALMAR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "660",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41660",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SALADOBLANCO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "660",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54660",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SALAZAR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "660",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15660",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN EDUARDO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "660",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05660",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN LUIS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "660",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23660",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAHAGÚN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "662",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17662",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAMANÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "662",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25662",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN JUAN DE RIOSECO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "663",
      CODIGO_DEPARTAMENTO: "94",
      CODIGO_DPTO_MPIO: "94663",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MAPIRIPANA (ANM)",
      CODIGO_TIPO_MUNICIPIO: "ANM",
    },
    {
      CODIGO_MUNICIPIO: "664",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15664",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN JOSÉ DE PARE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "664",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05664",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN PEDRO DE LOS MILAGROS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "665",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05665",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN PEDRO DE URABÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "665",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17665",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN JOSÉ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "666",
      CODIGO_DEPARTAMENTO: "97",
      CODIGO_DPTO_MPIO: "97666",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TARAIRA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "667",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05667",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN RAFAEL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "667",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13667",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN MARTÍN DE LOBA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "667",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15667",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN LUIS DE GACENO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "668",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41668",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN AGUSTÍN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "669",
      CODIGO_DEPARTAMENTO: "91",
      CODIGO_DPTO_MPIO: "91669",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO SANTANDER (ANM)",
      CODIGO_TIPO_MUNICIPIO: "ANM",
    },
    {
      CODIGO_MUNICIPIO: "669",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68669",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN ANDRÉS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "670",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76670",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN PEDRO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "670",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23670",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN ANDRÉS DE SOTAVENTO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "670",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54670",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN CALIXTO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "670",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05670",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN ROQUE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "670",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70670",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAMPUÉS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "670",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13670",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN PABLO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "671",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73671",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SALDAÑA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "672",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23672",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN ANTERO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "673",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68673",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN BENITO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "673",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15673",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN MATEO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "673",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54673",
      CODIGO_AREA_METRO: "05",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN CAYETANO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "673",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13673",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTA CATALINA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "674",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05674",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN VICENTE FERRER",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "675",
      CODIGO_DEPARTAMENTO: "08",
      CODIGO_DPTO_MPIO: "08675",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTA LUCÍA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "675",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73675",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN ANTONIO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "675",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47675",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SALAMINA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "675",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23675",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN BERNARDO DEL VIENTO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "676",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15676",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN MIGUEL DE SEMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "676",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41676",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTA MARÍA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "678",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73678",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN LUIS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "678",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70678",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN BENITO ABAD",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "678",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52678",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAMANIEGO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "678",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23678",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN CARLOS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "679",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68679",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN GIL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "679",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05679",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTA BÁRBARA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "680",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54680",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTIAGO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "680",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50680",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN CARLOS DE GUAROA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "681",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15681",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN PABLO DE BORBUR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "682",
      CODIGO_DEPARTAMENTO: "66",
      CODIGO_DPTO_MPIO: "66682",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTA ROSA DE CABAL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "682",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23682",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN JOSÉ DE URÉ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "682",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68682",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN JOAQUÍN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "683",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50683",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN JUAN DE ARAMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "683",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13683",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTA ROSA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "683",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52683",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANDONÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "684",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68684",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN JOSÉ DE MIRANDA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "685",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52685",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN BERNARDO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "685",
      CODIGO_DEPARTAMENTO: "08",
      CODIGO_DPTO_MPIO: "08685",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTO TOMÁS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "686",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15686",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTANA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "686",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05686",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTA ROSA DE OSOS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "686",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73686",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTA ISABEL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "686",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23686",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN PELAYO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "686",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50686",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN JUANITO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "686",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68686",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN MIGUEL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "687",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52687",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN LORENZO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "687",
      CODIGO_DEPARTAMENTO: "66",
      CODIGO_DPTO_MPIO: "66687",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTUARIO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "688",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13688",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTA ROSA DEL SUR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "689",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50689",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN MARTÍN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "689",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68689",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN VICENTE DE CHUCURÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "690",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15690",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTA MARÍA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "690",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05690",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTO DOMINGO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "690",
      CODIGO_DEPARTAMENTO: "63",
      CODIGO_DPTO_MPIO: "63690",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SALENTO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "692",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47692",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN SEBASTIÁN DE BUENAVISTA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "693",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52693",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN PABLO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "693",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19693",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN SEBASTIÁN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "693",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15693",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTA ROSA DE VITERBO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "694",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52694",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN PEDRO DE CARTAGO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "696",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15696",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTA SOFÍA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "696",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52696",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTA BÁRBARA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "697",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05697",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "EL SANTUARIO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "698",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19698",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTANDER DE QUILICHAO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "699",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52699",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTACRUZ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "701",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19701",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTA ROSA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "702",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70702",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN JUAN DE BETULIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "703",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47703",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN ZENÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "705",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68705",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTA BÁRBARA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "707",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47707",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTA ANA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "708",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70708",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN MARCOS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "710",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20710",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN ALBERTO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "711",
      CODIGO_DEPARTAMENTO: "50",
      CODIGO_DPTO_MPIO: "50711",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VISTAHERMOSA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "713",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70713",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN ONOFRE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "717",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70717",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN PEDRO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "718",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25718",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SASAIMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "720",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68720",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTA HELENA DEL OPÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "720",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47720",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTA BÁRBARA DE PINTO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "720",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15720",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SATIVANORTE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "720",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54720",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SARDINATA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "720",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52720",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAPUYES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "723",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15723",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SATIVASUR",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "736",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76736",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SEVILLA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "736",
      CODIGO_DEPARTAMENTO: "81",
      CODIGO_DPTO_MPIO: "81736",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SARAVENA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "736",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05736",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SEGOVIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "736",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25736",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SESQUILÉ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "740",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25740",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SIBATÉ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "740",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15740",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SIACHOQUE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "742",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70742",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN LUIS DE SINCÉ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "743",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54743",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SILOS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "743",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25743",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SILVANIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "743",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19743",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SILVIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "744",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13744",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SIMITÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "745",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25745",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SIMIJACA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "745",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27745",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SIPÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "745",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68745",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SIMACOTA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "745",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47745",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SITIONUEVO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "749",
      CODIGO_DEPARTAMENTO: "86",
      CODIGO_DPTO_MPIO: "86749",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SIBUNDOY",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "750",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20750",
      CODIGO_AREA_METRO: "06",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN DIEGO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "753",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15753",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SOATÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "753",
      CODIGO_DEPARTAMENTO: "18",
      CODIGO_DPTO_MPIO: "18753",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN VICENTE DEL CAGUÁN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "754",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25754",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SOACHA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "755",
      CODIGO_DEPARTAMENTO: "86",
      CODIGO_DPTO_MPIO: "86755",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN FRANCISCO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "755",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15755",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SOCOTÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "755",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68755",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SOCORRO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "756",
      CODIGO_DEPARTAMENTO: "18",
      CODIGO_DPTO_MPIO: "18756",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SOLANO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "756",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05756",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SONSÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "757",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15757",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SOCHA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "757",
      CODIGO_DEPARTAMENTO: "86",
      CODIGO_DPTO_MPIO: "86757",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN MIGUEL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "758",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25758",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SOPÓ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "758",
      CODIGO_DEPARTAMENTO: "08",
      CODIGO_DPTO_MPIO: "08758",
      CODIGO_AREA_METRO: "04",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SOLEDAD",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "759",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15759",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SOGAMOSO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "760",
      CODIGO_DEPARTAMENTO: "86",
      CODIGO_DPTO_MPIO: "86760",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTIAGO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "760",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13760",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SOPLAVIENTO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "760",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19760",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SOTARÁ PAISPAMBA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "760",
      CODIGO_DEPARTAMENTO: "99",
      CODIGO_DPTO_MPIO: "99760",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN JOSÉ DE OCUNE",
      CODIGO_TIPO_MUNICIPIO: "ANM",
    },
    {
      CODIGO_MUNICIPIO: "761",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05761",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SOPETRÁN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "761",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15761",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SOMONDOCO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "762",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15762",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SORA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "763",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15763",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SOTAQUIRÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "764",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15764",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SORACÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "769",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25769",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SUBACHOQUE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "770",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20770",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN MARTÍN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "770",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41770",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SUAZA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "770",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73770",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SUÁREZ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "770",
      CODIGO_DEPARTAMENTO: "08",
      CODIGO_DPTO_MPIO: "08770",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SUAN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "770",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68770",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SUAITA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "771",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70771",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SUCRE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "772",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25772",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SUESCA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "773",
      CODIGO_DEPARTAMENTO: "99",
      CODIGO_DPTO_MPIO: "99773",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CUMARIBO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "773",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68773",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SUCRE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "774",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15774",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SUSACÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "776",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15776",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SUTAMARCHÁN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "777",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25777",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SUPATÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "777",
      CODIGO_DEPARTAMENTO: "97",
      CODIGO_DPTO_MPIO: "97777",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PAPUNAHUA (ANM)",
      CODIGO_TIPO_MUNICIPIO: "ANM",
    },
    {
      CODIGO_MUNICIPIO: "777",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17777",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SUPÍA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "778",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15778",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SUTATENZA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "779",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25779",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SUSA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "780",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68780",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SURATÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "780",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13780",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TALAIGUA NUEVO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "780",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19780",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SUÁREZ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "781",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25781",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SUTATAUSA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "785",
      CODIGO_DEPARTAMENTO: "18",
      CODIGO_DPTO_MPIO: "18785",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SOLITA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "785",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19785",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SUCRE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "785",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25785",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TABIO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "786",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52786",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TAMINANGO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "787",
      CODIGO_DEPARTAMENTO: "20",
      CODIGO_DPTO_MPIO: "20787",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TAMALAMEQUE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "787",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27787",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TADÓ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "788",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52788",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TANGUA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "789",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05789",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TÁMESIS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "790",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15790",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TASCO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "790",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05790",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TARAZÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "791",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41791",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TARQUI",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "792",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05792",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TARSO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "793",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25793",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TAUSA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "794",
      CODIGO_DEPARTAMENTO: "81",
      CODIGO_DPTO_MPIO: "81794",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TAME",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "797",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25797",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TENA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "797",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41797",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TESALIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "798",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15798",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TENZA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "798",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47798",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TENERIFE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "798",
      CODIGO_DEPARTAMENTO: "91",
      CODIGO_DPTO_MPIO: "91798",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TARAPACÁ (ANM)",
      CODIGO_TIPO_MUNICIPIO: "ANM",
    },
    {
      CODIGO_MUNICIPIO: "799",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25799",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TENJO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "799",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41799",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TELLO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "800",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54800",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TEORAMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "800",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27800",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "UNGUÍA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "801",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41801",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TERUEL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "804",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15804",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TIBANÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "805",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25805",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TIBACUY",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "806",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15806",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TIBASOSA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "807",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41807",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TIMANÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "807",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25807",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TIBIRITA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "807",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19807",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TIMBÍO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "807",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23807",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TIERRALTA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "808",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15808",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TINJACÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "809",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19809",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TIMBIQUÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "809",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05809",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TITIRIBÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "810",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13810",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TIQUISIO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "810",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15810",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TIPACOQUE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "810",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54810",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TIBÚ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "810",
      CODIGO_DEPARTAMENTO: "27",
      CODIGO_DPTO_MPIO: "27810",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "UNIÓN PANAMERICANA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "814",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15814",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TOCA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "815",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25815",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TOCAIMA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "815",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23815",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TUCHÍN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "816",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15816",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TOGÜÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "817",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25817",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TOCANCIPÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "819",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05819",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TOLEDO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "820",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15820",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TÓPAGA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "820",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54820",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TOLEDO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "820",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70820",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SANTIAGO DE TOLÚ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "820",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68820",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TONA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "821",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19821",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TORIBÍO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "822",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15822",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TOTA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "823",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25823",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TOPAIPÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "823",
      CODIGO_DEPARTAMENTO: "70",
      CODIGO_DPTO_MPIO: "70823",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN JOSÉ DE TOLUVIEJO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "823",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76823",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TORO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "824",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19824",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TOTORÓ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "828",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76828",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TRUJILLO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "832",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15832",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TUNUNGUÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "832",
      CODIGO_DEPARTAMENTO: "08",
      CODIGO_DPTO_MPIO: "08832",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TUBARÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "834",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76834",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TULUÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "835",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52835",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "11",
      NOMBRE_MUNICIPIO: "SAN ANDRÉS DE TUMACO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "835",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15835",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TURMEQUÉ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "836",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13836",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TURBACO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "837",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15837",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TUTA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "837",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05837",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "09",
      NOMBRE_MUNICIPIO: "TURBO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "838",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13838",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TURBANA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "838",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52838",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TÚQUERRES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "839",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15839",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "TUTAZÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "839",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25839",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "UBALÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "841",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25841",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "UBAQUE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "842",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05842",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "URAMITA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "842",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15842",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ÚMBITA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "843",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25843",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VILLA DE SAN DIEGO DE UBATÉ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "845",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76845",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ULLOA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "845",
      CODIGO_DEPARTAMENTO: "19",
      CODIGO_DPTO_MPIO: "19845",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VILLA RICA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "845",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25845",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "UNE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "847",
      CODIGO_DEPARTAMENTO: "44",
      CODIGO_DPTO_MPIO: "44847",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "URIBIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "847",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05847",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "URRAO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "849",
      CODIGO_DEPARTAMENTO: "08",
      CODIGO_DPTO_MPIO: "08849",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "USIACURÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "851",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25851",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ÚTICA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "854",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05854",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VALDIVIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "854",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73854",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VALLE DE SAN JUAN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "855",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68855",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VALLE DE SAN JOSÉ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "855",
      CODIGO_DEPARTAMENTO: "23",
      CODIGO_DPTO_MPIO: "23855",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VALENCIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "855",
      CODIGO_DEPARTAMENTO: "44",
      CODIGO_DPTO_MPIO: "44855",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "URUMITA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "856",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05856",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VALPARAÍSO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "858",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05858",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VEGACHÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "860",
      CODIGO_DEPARTAMENTO: "18",
      CODIGO_DPTO_MPIO: "18860",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VALPARAÍSO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "861",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73861",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VENADILLO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "861",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05861",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VENECIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "861",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15861",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VENTAQUEMADA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "861",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68861",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VÉLEZ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "862",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25862",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VERGARA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "863",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76863",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VERSALLES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "865",
      CODIGO_DEPARTAMENTO: "86",
      CODIGO_DPTO_MPIO: "86865",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VALLE DEL GUAMUEZ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "867",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17867",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VICTORIA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "867",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68867",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VETAS",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "867",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25867",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VIANÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "869",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76869",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VIJES",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "870",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73870",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VILLAHERMOSA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "871",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54871",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VILLA CARO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "871",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25871",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VILLAGÓMEZ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "872",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68872",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VILLANUEVA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "872",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41872",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VILLAVIEJA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "873",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05873",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VIGÍA DEL FUERTE",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "873",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25873",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VILLAPINZÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "873",
      CODIGO_DEPARTAMENTO: "73",
      CODIGO_DPTO_MPIO: "73873",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VILLARRICA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "873",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13873",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VILLANUEVA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "873",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17873",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VILLAMARÍA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "874",
      CODIGO_DEPARTAMENTO: "54",
      CODIGO_DPTO_MPIO: "54874",
      CODIGO_AREA_METRO: "05",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VILLA DEL ROSARIO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "874",
      CODIGO_DEPARTAMENTO: "44",
      CODIGO_DPTO_MPIO: "44874",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VILLANUEVA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "875",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25875",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VILLETA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "877",
      CODIGO_DEPARTAMENTO: "17",
      CODIGO_DPTO_MPIO: "17877",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VITERBO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "878",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25878",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VIOTÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "879",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15879",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VIRACACHÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "883",
      CODIGO_DEPARTAMENTO: "94",
      CODIGO_DPTO_MPIO: "94883",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "SAN FELIPE (ANM)",
      CODIGO_TIPO_MUNICIPIO: "ANM",
    },
    {
      CODIGO_MUNICIPIO: "884",
      CODIGO_DEPARTAMENTO: "94",
      CODIGO_DPTO_MPIO: "94884",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PUERTO COLOMBIA (ANM)",
      CODIGO_TIPO_MUNICIPIO: "ANM",
    },
    {
      CODIGO_MUNICIPIO: "885",
      CODIGO_DEPARTAMENTO: "94",
      CODIGO_DPTO_MPIO: "94885",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "LA GUADALUPE (ANM)",
      CODIGO_TIPO_MUNICIPIO: "ANM",
    },
    {
      CODIGO_MUNICIPIO: "885",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05885",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "YALÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "885",
      CODIGO_DEPARTAMENTO: "86",
      CODIGO_DPTO_MPIO: "86885",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "VILLAGARZÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "885",
      CODIGO_DEPARTAMENTO: "41",
      CODIGO_DPTO_MPIO: "41885",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "YAGUARÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "885",
      CODIGO_DEPARTAMENTO: "52",
      CODIGO_DPTO_MPIO: "52885",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "YACUANQUER",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "885",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25885",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "YACOPÍ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "886",
      CODIGO_DEPARTAMENTO: "94",
      CODIGO_DPTO_MPIO: "94886",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "CACAHUAL (ANM)",
      CODIGO_TIPO_MUNICIPIO: "ANM",
    },
    {
      CODIGO_MUNICIPIO: "887",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05887",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "YARUMAL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "887",
      CODIGO_DEPARTAMENTO: "94",
      CODIGO_DPTO_MPIO: "94887",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "PANA PANA (ANM)",
      CODIGO_TIPO_MUNICIPIO: "ANM",
    },
    {
      CODIGO_MUNICIPIO: "888",
      CODIGO_DEPARTAMENTO: "94",
      CODIGO_DPTO_MPIO: "94888",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "MORICHAL (ANM)",
      CODIGO_TIPO_MUNICIPIO: "ANM",
    },
    {
      CODIGO_MUNICIPIO: "889",
      CODIGO_DEPARTAMENTO: "97",
      CODIGO_DPTO_MPIO: "97889",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "YAVARATÉ (ANM)",
      CODIGO_TIPO_MUNICIPIO: "ANM",
    },
    {
      CODIGO_MUNICIPIO: "890",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05890",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "YOLOMBÓ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "890",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76890",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "YOTOCO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "892",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76892",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "YUMBO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "893",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05893",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "YONDÓ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "894",
      CODIGO_DEPARTAMENTO: "13",
      CODIGO_DPTO_MPIO: "13894",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ZAMBRANO",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "895",
      CODIGO_DEPARTAMENTO: "76",
      CODIGO_DPTO_MPIO: "76895",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ZARZAL",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "895",
      CODIGO_DEPARTAMENTO: "05",
      CODIGO_DPTO_MPIO: "05895",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ZARAGOZA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "895",
      CODIGO_DEPARTAMENTO: "68",
      CODIGO_DPTO_MPIO: "68895",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ZAPATOCA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "897",
      CODIGO_DEPARTAMENTO: "15",
      CODIGO_DPTO_MPIO: "15897",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ZETAQUIRA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "898",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25898",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ZIPACÓN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "899",
      CODIGO_DEPARTAMENTO: "25",
      CODIGO_DPTO_MPIO: "25899",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ZIPAQUIRÁ",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "960",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47960",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ZAPAYÁN",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
    {
      CODIGO_MUNICIPIO: "980",
      CODIGO_DEPARTAMENTO: "47",
      CODIGO_DPTO_MPIO: "47980",
      CODIGO_AREA_METRO: "",
      CODIGO_DISTRITO: "",
      NOMBRE_MUNICIPIO: "ZONA BANANERA",
      CODIGO_TIPO_MUNICIPIO: "AM",
    },
  ];

  const cityName = cityList.find((city) => city.CODIGO_DPTO_MPIO === cod);

  if (cityName) {
    return cityName.NOMBRE_MUNICIPIO;
  }

  return false;
};
